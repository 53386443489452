@font-face {
  font-family: Roboto;
  src: url(./fonts/roboto/Roboto-Regular.ttf);
}
@font-face {
  font-family: Roboto Light;
  src: url(./fonts/roboto/Roboto-Light.ttf);
}
@import url('https://fonts.googleapis.com/css2?family=DM+Mono:ital,wght@0,300;0,400;0,500;1,300;1,400;1,500&family=Fredoka+One&display=swap');
@font-face {
  font-family: Roboto Bold;
  src: url(./fonts/roboto/Roboto-Bold.ttf);
}
@font-face {
  font-family: Lilita;
  src: url(./fonts/LilitaOne-Regular.ttf);
}
.App {
  text-align: center;
  /* transition-duration: 0.5ms ; */
  /* -webkit-transition: 590ms all linear; */
  /* background:-moz-radial-gradient(left, #ffffff 24%, #cba334 88%); */
  /* transition: color 0.5s linear; */
  /* -moz-transition: color 0.5s linear; 
  -webkit-transition: color 0.5s linear; 
  -o-transition: color 0.5s linear; 
  -ms-transition: color 0.5s linear;  */
  position: absolute;
  left:0;
  bottom:0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

body{
  background-color: black;
}

ul {
  list-style: none; /* Remove default bullets */
}

ul li::before {
  content: "\2022";  /* Add content: \2022 is the CSS Code/unicode for a bullet */
  color: red; /* Change the color */
  font-weight: bold; /* If you want it to be bold */
  display: inline-block; /* Needed to add space between the bullet and the text */
  width: 1em; /* Also needed for space (tweak if needed) */
  margin-left: -1em; /* Also needed for space (tweak if needed) */
}

.lds-default {
  display: inline-block;
  position: absolute;
  left:50%;
  top:45%;
  transform: (-50%,-50%);
  z-index: 9999;
  width: 80px;
  height: 80px;
}
.lds-default div {
  position: absolute;
  width: 6px;
  height: 6px;
  background: #fff;
  border-radius: 50%;
  animation: lds-default 1.2s linear infinite;
}
.lds-default div:nth-child(1) {
  animation-delay: 0s;
  top: 37px;
  left: 66px;
}
.lds-default div:nth-child(2) {
  animation-delay: -0.1s;
  top: 22px;
  left: 62px;
}
.lds-default div:nth-child(3) {
  animation-delay: -0.2s;
  top: 11px;
  left: 52px;
}
.lds-default div:nth-child(4) {
  animation-delay: -0.3s;
  top: 7px;
  left: 37px;
}
.lds-default div:nth-child(5) {
  animation-delay: -0.4s;
  top: 11px;
  left: 22px;
}
.lds-default div:nth-child(6) {
  animation-delay: -0.5s;
  top: 22px;
  left: 11px;
}
.lds-default div:nth-child(7) {
  animation-delay: -0.6s;
  top: 37px;
  left: 7px;
}
.lds-default div:nth-child(8) {
  animation-delay: -0.7s;
  top: 52px;
  left: 11px;
}
.lds-default div:nth-child(9) {
  animation-delay: -0.8s;
  top: 62px;
  left: 22px;
}
.lds-default div:nth-child(10) {
  animation-delay: -0.9s;
  top: 66px;
  left: 37px;
}
.lds-default div:nth-child(11) {
  animation-delay: -1s;
  top: 62px;
  left: 52px;
}
.lds-default div:nth-child(12) {
  animation-delay: -1.1s;
  top: 52px;
  left: 62px;
}
@keyframes lds-default {
  0%, 20%, 80%, 100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.5);
  }
}
